import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor() {

  }

  send(httpClient:HttpClient, email:string, mobile:string, message:string, callback:Function = null, callbackContext:Object = null) {

    let headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    httpClient.post('/send-mail', {
      "email": email,
      "mobile": mobile,
      "demand": message
    }, {
      headers: headers
    }).subscribe(data => {
      console.log(data);
      if(callback) callback.call(callbackContext);
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';
import {NgForm} from '@angular/forms';
import { SendEmailService } from '../send-email.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Entry } from 'contentful';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  public texte:string;
  public merci:string;
  private httpClient:HttpClient;
  public articleClass:string;
  private productId:String;
  public relatedProduct:Entry<any>;

  constructor(private route: ActivatedRoute, public contentful: ContentfulService, private markdownService: MarkdownService, private http:HttpClient, private router: Router) {
    this.markdownService = markdownService;
    this.httpClient = http;
    this.router = router;
    this.contentful.emitter.on('ready', this.render.bind(this));
    this.articleClass = '';

    this.route.params.subscribe(params => {
      if(params.productId) {
        this.productId = params.productId
      }
    });
  }

  ngOnInit() {
    if(this.contentful.isReady) this.render();
  }

  private render() {
    this.texte = this.markdownService.compile(this.contentful.texte('Contact - Texte'));
    this.merci = this.markdownService.compile(this.contentful.texte('Contact - Merci'));
    if(this.productId) this.relatedProduct = this.contentful.getProductById(this.productId);
  }


  public submitForm(f:NgForm) {
    if(f.valid) {
      this.articleClass = 'loading';
      const sendEmail = new SendEmailService();
      let message = f.value.message;
      if(this.relatedProduct) {
        let relatedProduct = this.relatedProduct;
        if(f.value['related-product'] != '') relatedProduct = f.value['related-product'];
        message += '\n Produit associé : ' + relatedProduct;
      }
      sendEmail.send(this.httpClient, f.value.email, f.value.mobile, message, this.onEmailSent, this);
    }
  }

  public onEmailSent() {
    this.articleClass = 'sent';
  }
}

import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-cgv',
  templateUrl: './cgv.component.html',
  styleUrls: ['./cgv.component.scss']
})
export class CgvComponent implements OnInit {


  public texte:string;
  constructor(public contentful: ContentfulService, private markdownService: MarkdownService) {
    this.markdownService = markdownService;
    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }

  private render() {
    this.texte = this.markdownService.compile(this.contentful.texte('CGV - Texte'));
  }

}

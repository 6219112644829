import { Component, OnInit, ViewChild } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from './contentful.service';
import { Title,  Meta } from '@angular/platform-browser';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  menuClass = 'menu--close';

  public locale:string = '';

  private textesCourts: Entry<any>[] = [];


  @ViewChild(HeaderMobileComponent)
  private headerMobile: HeaderMobileComponent;

  constructor(public contentful: ContentfulService, private meta: Meta, private title: Title) {
    this.contentful.emitter.on('ready', this.contentfulReady.bind(this));
  }

  contentfulReady() {
    this.locale = this.contentful.locale;
    this.title.setTitle(this.contentful.texte('Balise titre'));
    this.meta.addTag({ name: 'description', content: this.contentful.texte('Balise description') });
  }

  public menuOpened() {
    this.menuClass = 'menu--open';
  }
  public menuClosed() {
    this.menuClass = 'menu--close';
  }
  public closeMenu() {
    if(this.headerMobile.opened) this.headerMobile.toggleMenu();
  }
}

// ./src/app/contentful.service.ts
import { Injectable } from '@angular/core';
// import Contentful createClient and type for `Entry`
import { createClient, Entry, Asset } from 'contentful';
import { EventEmitter } from 'events';

// configure the service with tokens and content type ids
// SET YOU OWN CONFIG here
const CONFIG = {
  space: '65dl36lryxe8',
  accessToken: 'b7ad7969d908d0a7eb9d66028eb35846f875990cf7306a8adbe236d1c461bc4d',

  contentTypeIds: {
    textesCourts: 'textes',
    textesLongs: 'textesLongs',
    slider: 'slider',
    productCategories: 'categorieDeProduit',
    products: 'produit'
  }
}

@Injectable()
export class ContentfulService {

  public locale:string;
  public emitter:EventEmitter = new EventEmitter();
  public isReady:boolean = false;

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });


  private textesCourts:Entry<any>[];
  private textesLongs:Entry<any>[];
  private textes:Entry<any>[];
  private assets:Entry<any>[];
  public slider:Entry<any>[];
  public productCategories:Entry<any>[];
  public products:Entry<any>[];


  constructor() {

    this.getTextesCourts().then((textesCourts) => {
      this.textesCourts = textesCourts;
      this.checkReady();
    });

    this.getTextesLongs().then((textesLongs) => {
      this.textesLongs = textesLongs;
      this.checkReady();
    });

    this.getAssets().then((assets) => {
      this.assets = assets;
      this.checkReady();
    });

    this.getSlider().then((slider) => {
      this.slider = slider;
      this.checkReady();
    });

    this.getProductCategories().then((productCategories) => {
      this.productCategories = productCategories;
      this.checkReady();
    });

    this.getProducts().then((productS) => {
      this.products = productS;
      this.checkReady();
    });

  }

  private checkReady() {
    if(!this.isReady && this.textesLongs && this.textesCourts && this.assets && this.slider && this.productCategories && this.products) this.ready();
  }

  private ready() {
    this.isReady = true;
    document.documentElement.lang = this.getLocale();
    this.emitter.emit('ready');
  }

  public texte(id):string {
    let result = '';
    this.textes = [];
    if(this.textesCourts) {
      this.textesCourts.forEach(texteData => {
        this.textes.push(texteData.fields);
      });
    }
    if(this.textesLongs) {
      this.textesLongs.forEach(texteData => {
        this.textes.push(texteData.fields);
      });
    }
    this.textes.forEach(texteData => {
      if(texteData['identifiantNePasModifier'] === id) result = texteData['texte'];
    });
    return result;
  }

  public image(title):string {
    let result = '';
    if(this.assets) {
      this.assets.forEach(assetData => {
        if(assetData['fields'].title === title) result = assetData['fields'].file.url;
      });
    }
    return result;
  }

  getTextesCourts(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.textesCourts,
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }
  getTextesLongs(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.textesLongs,
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }

  getAssets(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getAssets(Object.assign({
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }

  getSlider(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.slider,
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }

  getProductCategories(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.productCategories,
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }

  getProducts(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.products,
      locale: this.getLocale()
    }, query))
    .then(res => res.items);
  }

  getProductsByCategory(categoryId: String): Entry<any>[] {
    let products = [];
    this.products.forEach(product => {
      if(product.fields.categorie.fields.identifiant == categoryId) {
        products.push(product);
      }
    });
    products = products.sort((product1, product2) => {
      return new Date(product2.sys.updatedAt).getTime() - new Date(product1.sys.updatedAt).getTime();
    });
    products = products.sort((product1, product2) => {
      return (product2.fields.statut ? 1 : 0) - (product1.fields.statut ? 1 : 0);
    });
    return products;
  }

  getProductCategoryById(categoryId: String): Entry<any> {
    let categoryFounded = null;
    this.productCategories.forEach(category => {
      if(category.fields.identifiant == categoryId) {
        categoryFounded = category;
      }
    });
    return categoryFounded;
  }

  getProductById(productId: String): Entry<any> {
    let productFounded = null;
    this.products.forEach(product => {
      if(product.fields.identifiant == productId) {
        productFounded = product;
      }
    });
    return productFounded;
  }





  private getLocale():string {
    let locale = window['readCookie']('locale') ? window['readCookie']('locale') : (window.navigator.language || window.navigator['userLanguage']);
    if(locale != 'en' && locale != 'fr') locale = 'fr';
    window['createCookie']('locale', locale);
    this.locale = locale;
    return this.locale;
  }
}

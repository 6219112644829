import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  public texte:string;
  constructor(public contentful: ContentfulService, private markdownService: MarkdownService) {
    this.markdownService = markdownService;
    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }

  private render() {
    this.texte = this.markdownService.compile(this.contentful.texte('About - Texte'));
  }
}

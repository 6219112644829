import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entry } from 'contentful';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {


  public catId:String;
  public productId:String;
  public product:Entry<any>;
  public withDelivery:Boolean;
  public photos:Entry<any>[];
  public photoIndex;
  public photoUrl:String;
  public informations:String;

  constructor(private route: ActivatedRoute, public contentful: ContentfulService, private elementRef:ElementRef, private markdownService: MarkdownService) {
    this.markdownService = markdownService;

    this.withDelivery = true;

    this.route.params.subscribe(params => {
      this.catId = params.catId;
      this.productId = params.productId;
    });

    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    if(this.contentful.isReady) this.render();
  }

  public deliveryChange(value:String) {
    this.withDelivery = value == 'yes';
  }

  private render() {
    this.product = this.contentful.getProductById(this.productId);
    console.log(this.product);
    this.photos = this.product.fields.photos;
    this.photoIndex = 0;
    this.photoUrl = this.getPhotoUrl();
    const paypalContainer = this.elementRef.nativeElement.querySelector('.paypal');
    paypalContainer.insertAdjacentHTML('beforeend', this.getPaypalAddButton('paypal-add-button', this.product.fields.paypalId));
    paypalContainer.insertAdjacentHTML('beforeend', this.getPaypalAddButton('paypal-add-button-no-delivery', this.product.fields.paypalIdSansLivraison));
    this.informations = this.markdownService.compile(this.contentful.texte('Informations ' + this.catId));
  }

  private getPhotoUrl() {
    return this.photos[this.photoIndex].fields.file.url;
  }

  public showBasket() {
    this.elementRef.nativeElement.querySelector('.paypal-basket').submit();
  }

  public addToBasket() {
    if(this.withDelivery) {
      this.elementRef.nativeElement.querySelector('.paypal-add-button').submit();
    }
    else {
      this.elementRef.nativeElement.querySelector('.paypal-add-button-no-delivery').submit();
    }
  }

  public thumbOver(photoUrl) {
    console.log('thumbOver '+photoUrl);
    this.photoUrl = photoUrl;
  }

  private getPaypalAddButton(paypalClass, paypalId) {
    return '<form class="' + paypalClass + '" target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post"><input type="hidden" name="cmd" value="_s-xclick"><input type="hidden" name="hosted_button_id" value="' + paypalId + '"><img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1"></form>'
  }

}

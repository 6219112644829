import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.html',
  styleUrls: ['./header-mobile.scss']
})
export class HeaderMobileComponent {

  title = 'Atelier Bama Traoré'
  menuClass = 'menu--close';
  opened = false;
  @Output() openMenu = new EventEmitter<any>();
  @Output() closeMenu = new EventEmitter<any>();

  public toggleMenu() {
    this.opened = !this.opened;
    if(this.opened) {
      this.openMenu.emit(null);
      this.menuClass='menu--open';
    }
    else {
      this.closeMenu.emit(null);
      this.menuClass='menu--close';
    }
    return false;
  }

}

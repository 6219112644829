import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';

import {NavigationComponent} from './navigation/navigation.component';
import {HeaderMobileComponent} from './header-mobile/header-mobile.component';

import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ContentfulService } from './contentful.service';
import { MarkdownDirective } from './MarkdownDirective';
import { ContactComponent } from './contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ShopComponent } from './shop/shop.component';
import { ProductComponent } from './product/product.component';
import { LegalsComponent } from './legals/legals.component';
import { CgvComponent } from './cgv/cgv.component';
import { ThanksComponent } from './thanks/thanks.component';
import { ShareButtonsModule } from '@ngx-share/buttons';

const appRoutes: Routes = [
  { path: '',
    component: HomeComponent
  },
  { path: 'a-propos',
    component: AboutComponent
  },
  { path: 'about',
    component: AboutComponent
  },
  { path: 'contact',
    component: ContactComponent
  },
  { path: 'contact/:productId',
    component: ContactComponent
  },
  { path: 'legals',
    component: LegalsComponent
  },
  { path: 'cgv',
    component: CgvComponent
  },
  { path: 'thanks',
    component: ThanksComponent
  },
  { path: 'shop/:catId',
    component: ShopComponent
  },
  { path: 'shop/:catId/:productId',
    component: ProductComponent
  },
  { path: '**',
    component: HomeComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderMobileComponent,
    NavigationComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    MarkdownDirective,
    ShopComponent,
    ProductComponent,
    LegalsComponent,
    CgvComponent,
    ThanksComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    SwiperModule,
    MarkdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ShareButtonsModule.forRoot()
  ],
  providers: [ContentfulService],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Output, EventEmitter } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  lang = 'fr'

  public productCategories:Entry<any>[];
  public showMenu:Boolean;

  public maintenance = false;

  @Output() navigationClickEmitter = new EventEmitter<any>();

  constructor(public contentful: ContentfulService) {
    this.showMenu = false;
    this.contentful.emitter.on('ready', this.contentfulReady.bind(this));
  }

  private contentfulReady() {
    if(this.contentful.texte('maintenance')  && window.location.hostname === 'www.atelierbamatraore.com') {
      this.maintenance = true;
    }
    this.productCategories = this.contentful.productCategories;
  }

  navigationClick() {
    this.navigationClickEmitter.emit(null);
    document.querySelector('.header-mobile').className = 'header-mobile';
  }

  toogleShopMenu($event) {
    this.showMenu = !this.showMenu;
    $event.preventDefault();
  }

  changeLang(lang:string) {
    window['createCookie']('locale', lang);
    window.location.reload();
  }
}

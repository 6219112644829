import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-legals',
  templateUrl: './legals.component.html',
  styleUrls: ['./legals.component.scss']
})
export class LegalsComponent implements OnInit {

  public texte:string;
  constructor(public contentful: ContentfulService, private markdownService: MarkdownService) {
    this.markdownService = markdownService;
    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }

  private render() {
    this.texte = this.markdownService.compile(this.contentful.texte('Mentions - Texte'));
  }

}

import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  public texte:string;
  constructor(public contentful: ContentfulService, private markdownService: MarkdownService) {
    this.markdownService = markdownService;
    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }

  private render() {
    this.texte = this.markdownService.compile(this.contentful.texte('Merci - Texte'));
  }

}

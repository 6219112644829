import { Component, OnInit, ElementRef } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { ContentfulService } from '../contentful.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  public sanitizer:DomSanitizer;

  public slidesDesktop:Array<string>;
  public slidesMobile:Array<string>;

  public maintenance = false;

  public configDesktop: SwiperConfigInterface = {
    pagination: true,
    effect: 'fade',
    speed: 1400,
    autoplay: {
      delay: 4000
    },
    loop: true,
    simulateTouch: false
  };
  public configMobile: SwiperConfigInterface = {
    pagination: true,
    autoplay: {
      delay: 4000
    },
    loop: true
  };

  constructor(public contentful: ContentfulService, sanitizer: DomSanitizer) {
    this.sanitizer = sanitizer;
    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }
  private render() {
    if(this.contentful.texte('maintenance') && window.location.hostname === 'www.atelierbamatraore.com') {
      this.maintenance = true;
      return;
    }
    if(this.contentful.slider) {
      let slidesDesktop = [];
      this.contentful.slider[0].fields.slidesDesktop.forEach(slideDesktop => {
        slidesDesktop.push(slideDesktop.fields.file.url);
      });
      this.slidesDesktop = this.shuffle(slidesDesktop);
      this.configDesktop.pagination = this.pagination;

      let slidesMobile = [];
      this.contentful.slider[0].fields.slidesMobile.forEach(slideMobile => {
        slidesMobile.push(slideMobile.fields.file.url);
      });
      this.slidesMobile = this.shuffle(slidesMobile);
      this.configMobile.pagination = this.pagination;
      document.querySelector('.header-mobile').className = 'header-mobile transparent';
    }
  }

  private shuffle(array:Array<string>) {
    let m = array.length, t, i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }
}

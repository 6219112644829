import { Directive, ElementRef, AfterContentInit } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { ContentfulService } from './contentful.service';

@Directive({ selector: '[markdownContentful]' })

export class MarkdownDirective {

    constructor(private el: ElementRef, private markdownService: MarkdownService, public contentful: ContentfulService) {
      //el.nativeElement.style.backgroundColor = 'yellow';
      this.contentful.emitter.on('ready', this.contentfulReady.bind(this));
    }

    contentfulReady() {
      this.render();
    }
    render() {
      const renderHtml = this.markdownService.compile(this.el.nativeElement.innerHTML);
      if(renderHtml != '') this.el.nativeElement.innerHTML = renderHtml;
    }
}

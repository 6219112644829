import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entry } from 'contentful';
import { ContentfulService } from '../contentful.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  public catId:String;
  public products:Entry<any>[];
  public productCategory:Entry<any>;

  constructor(private route: ActivatedRoute, private contentful: ContentfulService) {

    this.route.params.subscribe(params => {
      this.catId = params.catId;
      this.render();
    });

    this.contentful.emitter.on('ready', this.render.bind(this));
  }

  ngOnInit() {
    this.render();
  }

  private render() {
    if(this.contentful.isReady) {
      this.productCategory = this.contentful.getProductCategoryById(this.catId);
      this.products = this.contentful.getProductsByCategory(this.catId);
      console.log(this.products);
    }
  }

}
